import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useEffect } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import sendPageview from "../GoogleAnalytics/ga";
import { logUserAction } from '../api';

// FirebaseUI není (zatím) kompatibilní s Firebase v9, proto je zde použitá V9 compat knihovna
export default function SignUp(props: Props) {
	useEffect(() => {
		sendPageview("sign-up-display");
	}, []);

	const uiConfig = {
		signInFlow: "popup",
		signInSuccessUrl: props.loggedInUrl ?? window.location.href,
		signInOptions: [
			firebase.auth.EmailAuthProvider.PROVIDER_ID,
			firebase.auth.GoogleAuthProvider.PROVIDER_ID,
			firebase.auth.FacebookAuthProvider.PROVIDER_ID,
			firebase.auth.GithubAuthProvider.PROVIDER_ID,
		],
		callbacks: {
			signInSuccessWithAuthResult: (
				authResult: any
			) => {
				if (authResult.additionalUserInfo.isNewUser) {
					sendPageview("sign-up-completed");
					logUserAction(authResult.user.uid, authResult.user.email, "User registered");
				} else {
					logUserAction(authResult.user.uid, authResult.user.email, "User logged in");
				}
				return false;
			},
		},
	};

	return (
		<>
			<div className="login-container ms-auto me-auto text-center">
				<a href="https://www.apponfly.com" rel="noopener">
					<img
						src="/logo-large.png"
						height="100"
						className="mt-5"
						alt="logo"
					/>
				</a>
				{props.preHeading && (
					<p
						className="my-3"
						dangerouslySetInnerHTML={props.preHeading}
					></p>
				)}
				{props.heading && <h1 className="h2 mt-4">{props.heading}</h1>}
				{props.postHeading && (
					<p
						className="mt-3"
						dangerouslySetInnerHTML={props.postHeading}
					></p>
				)}
				<div className="mt-5">
					<StyledFirebaseAuth
						uiConfig={uiConfig}
						firebaseAuth={firebase.auth()}
					/>
				</div>
			</div>
		</>
	);
}

interface Props {
	loggedInUrl?: string | null;
	preHeading?: any | null;
	heading?: string | null;
	postHeading?: any | null;
}

import { useRef } from "react";
import useRemoteDesktopConnection, { RemoteDesktopConnectionProps } from "./useRemoteDesktopConnection";
import { RDP_CONNECTION_STATE } from "./RdpConnectionState";

function RemoteDesktopConnection({subscriptionId, vmId}: RemoteDesktopConnectionProps) {
    
    const {
        rdp,
        rdpPwd, 
        rdpPwdShown,
        ipIsValid, 
        isPwdCopied, 
        togglePasswordShow, 
        toggleRdp, 
        copyRdpPwd, 
        handleRdpIpBlur} = useRemoteDesktopConnection(subscriptionId, vmId);

    const passwordInputRef = useRef<HTMLInputElement>(null);

    return (
        <div id="rdpBlock">
            {rdp.lastError && (
                    <p className="alert alert-danger mt-4 fs-5">Enabling RDP connection failed.</p>
                )}
            <div className="row align-items-center py-3">
                <label className="col-md-6 fs-5" htmlFor="rdpSwitch">
                    Remote Desktop Connection
                </label>
                <div className="col-md-4">
                    <div className="row g-3 align-items-center">
                        <div className="col-auto form-switch form-switch-lg ps-3">
                            <input
                                className="form-check-input ms-0"
                                type="checkbox"
                                role="switch"
                                id="rdpSwitch"
                                checked={[RDP_CONNECTION_STATE.ENABLING, RDP_CONNECTION_STATE.ENABLED].includes(rdp.state)}
                                onChange={() => toggleRdp(rdp.state === RDP_CONNECTION_STATE.ENABLED ? false : true)}
                                disabled={[RDP_CONNECTION_STATE.ENABLING, RDP_CONNECTION_STATE.DISABLING].includes(rdp.state)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`row align-items-center py-3 ${rdp.state === RDP_CONNECTION_STATE.DISABLED ? "opacity-50" : ""}`}>
                <label className="col-md-6 fs-5" htmlFor="rdpIp">
                    RDP access allowed from IP address
                </label>
                <div className="col-md-6">
                    <div className="row g-3 align-items-center">
                        <div className="col-auto ps-3">
                            {rdp.state === RDP_CONNECTION_STATE.ENABLED && (
                                <input
                                    className="form-control ms-0"
                                    style={{ width: "250px", border: ( ipIsValid ? "1px solid #1db0ea" : "2px solid #eb1018") }}
                                    type="text"
                                    id="rdpIp"
                                    defaultValue={rdp.allowedIpAddress}
                                    onBlur={handleRdpIpBlur}
                                />
                            )}
                            {rdp.state === RDP_CONNECTION_STATE.DISABLED && (
                                <div style={{ height: "38px", display: "flex", alignItems: "center" }}>&ndash;</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`row align-items-top py-3 ${rdp.state === RDP_CONNECTION_STATE.DISABLED ? "opacity-50" : ""}`}>
                <label className="col-md-6 fs-5">Your RDP login details</label>
                <div
                    className="col-md-6 fs-5 d-grid"
                    style={{ gridAutoRows: "minmax(38px, 1fr)", position: "relative" }}
                >
                    <div className="row gx-1 align-items-center">
                        <label className="col-md-4 ps-3">Computer:</label>

                        <div className="col-auto">{rdp.state === RDP_CONNECTION_STATE.ENABLED ? process.env.REACT_APP_RDP_URL + ":" + (rdp.port ? rdp.port : "-") : "-"}</div>
                    </div>

                    <div className="row gx-1 align-items-center">
                        <label className="col-md-4 ps-3">Username:</label>

                        <div className="col-auto">{rdp.state === RDP_CONNECTION_STATE.ENABLED ? "Administrator" : "-"}</div>
                    </div>

                    <div className="row gx-1 align-items-center">
                        <label className="col-md-4 ps-3">Password:</label>

                        {rdp.state === RDP_CONNECTION_STATE.ENABLED && (
                            <div className="col-md-8">
                                <div className="d-flex">
                                    <input
                                        className="form-control ms-0 form-pwd"
                                        type={rdpPwdShown ? "text" : "password"}
                                        id="rdpPwd"
                                        ref={passwordInputRef}
                                        value={rdpPwd ?? "*****"}
                                        onBlur={(e) => {
                                            if (e?.relatedTarget?.id === "show-pwd-button") return;
                                            togglePasswordShow(false)}
                                        }
                                        disabled
                                        />
                                    <button
                                        id="show-pwd-button"
                                        className="btn btn-link ms-2 p-0"
                                        onClick={() => {
                                            const isShown = !rdpPwdShown;
                                            togglePasswordShow(!rdpPwdShown);
                                            if (passwordInputRef?.current) {
                                                if (isShown) {
                                                    passwordInputRef.current.disabled = false;
                                                    passwordInputRef.current.focus();
                                                } else {
                                                    passwordInputRef.current.blur();
                                                    passwordInputRef.current.disabled = true;
                                                }
                                            }
                                        }}
                                    >
                                        <i className={"bi fs-5 bi-eye" + (!rdpPwdShown ? "-slash" : "")}></i>
                                    </button>
                                    <button id="copy-pwd-button" className="btn btn-link ms-2 p-0" onClick={() => copyRdpPwd()}>
                                        <i className="bi bi-clipboard fs-5"></i>
                                    </button>
                                </div> 
                                {isPwdCopied && <span style={{ position: "absolute", fontSize: "0.75rem" }}>Copied!</span>}
                            </div>
                        )}

                        {rdp.state === RDP_CONNECTION_STATE.DISABLED && <div className="col-auto">-</div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RemoteDesktopConnection;



import { useContext } from "react";
import { UserContext } from "./UserContext";
import { getAuth } from "firebase/auth";
import { logUserAction } from '../api';

export default function LogoutButton() {
	const { user } = useContext(UserContext);
	return (
		<span
			onClick={() => {
				if (user)
					logUserAction(user.id, user.email, "User logged out");
				getAuth().signOut();
			}}
			style={{ cursor: "pointer" }}
		>
			<img
				src="https://img.icons8.com/ios-glyphs/30/000000/shutdown.png"
				alt="logout"
			/>
		</span>
	);
}

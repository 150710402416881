import { useContext, useEffect, useState } from "react";
import firebaseApp from "../Firebase/firebase";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { UserContext } from "./UserContext";
import { clearCookie } from "../cookie";
import SignUp from "./SignUp";


interface Props {
    children: JSX.Element[] | JSX.Element;
    loggedInUrl?: string | null;
    preHeading?: any | null;
    heading?: string | null;
    postHeading?: any | null;
}

export default function RequireAuthenticated(props: Props) {
    const [isLoading, setIsLoading] = useState(true);
    const { user, setUser } = useContext(UserContext);

    useEffect(() => {
        const auth = getAuth(firebaseApp);
        const unregisterAuthObserver = auth.onAuthStateChanged(async (u) => {
            if (u) {
                const firestore = getFirestore(firebaseApp);
                const docRef = doc(firestore, "customers", u.uid);
                const customerDocSnapshot = await getDoc(docRef);

                let currency: string | null = null;
                let stripeLink: string = "";
                if (customerDocSnapshot.exists()) {
                    currency = customerDocSnapshot.data()?.currency;
                    stripeLink = customerDocSnapshot.data()?.stripeLink;
                }
                const token = await u.getIdToken();

                setUser({
                    id: u.uid,
                    name: u.displayName,
                    email: u.email,
                    token: token,
                    currency: currency,
                    stripeLink: stripeLink,
                });
            } else {
                clearCookie("X-Subscription");
                setUser(null);
            }

            setIsLoading(false);
        });
        return () => unregisterAuthObserver();
    }, [setUser]);

    if (isLoading) {
        return <em>Loading...</em>;
    } else if (user) {
        return <>{props.children}</>;
    } else {
        return (
            <SignUp
                loggedInUrl={props.loggedInUrl}
                preHeading={props.preHeading}
                heading={props.heading}
                postHeading={props.postHeading}
            />
        );
    }
}
